const wcTitleElemsSelector = '.wcproductQuantity-tile';
const selectBtnSelector = '.selectbtn';
const globalAlertSelector = '.b-global_alerts-item.m-hide';

function sendProductQuantity() {
    var prodQuantityList = [];
    $(wcTitleElemsSelector).each(function () {
        var productId = $(this)[0].dataset.pid;
        var productShortName = $(this)[0].dataset.shortname;
        var productquantity = $(this).find('input[type="radio"]:checked').val();
        var obj = {
            productId: productId,
            productquantity: productquantity,
            productShortName: productShortName
        };
        prodQuantityList.push(obj);
    });
    var result = JSON.stringify(prodQuantityList);
    return { selectedPreferences: result };
}

function ProductsQuantityAjax(url='') {
    var isProductPreferenceAllowed = $('[name=isProductPreferenceAllowed]').val();
    var url = url || $('#send-productquantity-data').val();
    let data = {}
    if(isProductPreferenceAllowed === 'true') {
        data = sendProductQuantity();
    } else {
        let productTiles = $('.wcproduct-selectionlist .wcproduct-tile.selected');
        let winePreferenceTypes = [];
        productTiles.each((index, productTile) => {
            winePreferenceTypes.push($(productTile).data('pid'));
        });
        var result = JSON.stringify(winePreferenceTypes);
        data = { selectedPreferenceIDs: result };
    }
    // @ts-ignore
    $.ajax({
        url: url,
        data: data,
        type: 'GET',
        dataType: 'JSON',
        success: function (res) {
            if (res && res.loggedIn) {
                window.location.href = res.redirectUrl;
            } else if (res && res.renderedTemplate) {
                const selectedTier = $('.wcproduct-tile.selected').attr('data-pid');
                const WCStepName = 'Type';
                WCSignUpStepGTM(WCStepName, selectedTier);
                $('.mr-login-form').html(res.renderedTemplate);
                $('.mr-login-form #dwfrm_login_email').focus();
            }
        }
    });
}

function removeselect(removeid) {
    var seletcetdListItem = $(`.wcproduct-selectionlist .wcproduct-tile.selected[data-pid=${removeid}]`);
    if (seletcetdListItem) {
        $(seletcetdListItem).removeClass('selected');
        $(seletcetdListItem).find(selectBtnSelector).text('Select');
        $(seletcetdListItem).find(selectBtnSelector).removeClass('selected');
    }
}

function sendlist() {
    var milkrunSelectedList = [];
    var selectedList = $('.wcproduct-selectionlist .wcproduct-tile.selected');
    selectedList.each(function (index) {
        milkrunSelectedList.push(selectedList[index].dataset.pid);
    });
    var result = JSON.stringify(milkrunSelectedList);
    return { selectedPreferenceIDs: result };
}

function ajaxsend() {
    var url = $('#send-product-data').val();
    var data = sendlist();
    // @ts-ignore
    $.ajax({
        url: url,
        data: data,
        type: 'GET',
        dataType: 'JSON',
        success: function (response) {
            var result = response.result;
            if(result) {
                var iterator = Object.keys(result);
                const wcproductQuantitySelector = '.wcproduct-qtyselectionlist';
                $(wcproductQuantitySelector).html('');
                var appendData;
                iterator.forEach(function (index) {
                    var SvgImage = `<?xml version="1.0" encoding="UTF-8"?>
                    <svg width="9px" height="24px" viewBox="0 0 9 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>Bottle svg image</title>
                        <g id="Wine-Club-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Milk-RUN-Sign-up-With-Account-details-Known" transform="translate(-358.000000, -2479.000000)"
                                fill="currentColor" fill-rule="nonzero">
                                <g id="Group-2" transform="translate(224.000000, 2271.000000)">
                                    <g id="Group-3" transform="translate(26.000000, 200.000000)">
                                        <g id="CTA/pill1/unselected" transform="translate(92.000000, 0.000000)">
                                            <g id="Group-2" transform="translate(16.025000, 8.000000)">
                                                <path d="M4.88642483,-1.77635684e-15 C5.18916064,-1.77635684e-15 5.22676009,
                                                0.221127155 5.24355285,0.503749323 C5.23091446,4.71464408 5.42660581,
                                                7.21712005 5.83062689,8.01117724 C6.4366585,9.20226304 8,10.2888449 8,12.9333333 L8,
                                                22.4 C8,23.2836556 7.23385626,24 6.28877005,24 L1.71122995,24 C0.766143744,
                                                24 8.8817842e-16,23.2836556 8.8817842e-16,22.4 L8.8817842e-16,
                                                12.9333333 C8.8817842e-16,10.2352509 1.53482249,9.20226304 2.16937311,
                                                8.01117724 C2.59240686,7.21712005 2.79196193,4.71464408 2.76803832,
                                                0.503749323 C2.78483108,0.221127155 2.81083936,-1.77635684e-15 3.11357517,
                                                -1.77635684e-15 L4.88642483,-1.77635684e-15 Z"
                                                id="Combined-Shape" transform="translate(4.000000, 12.000000) scale(-1, 1)
                                                translate(-4.000000, -12.000000) "></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>`;
                    const { quantitySelectionOffered } = result[index];

                    let qtyMarkup = '<div class="quantity-pick-container">';
                    if(quantitySelectionOffered.length) {
                        quantitySelectionOffered.forEach((element, qtyIndex) => {
                            qtyMarkup = `${qtyMarkup}
                            <div tabindex=0>
                            <input class="radio__input" type="radio" name="${result[index].productId}_quantity" value="${element}"
                            data-actualprice ="${result[index].actualPrices[qtyIndex].currencyCode}${result[index].actualPrices[qtyIndex].value}"
                            data-discountedprice = "${result[index].discountedPrices[qtyIndex].currencyCode}${result[index].discountedPrices[qtyIndex].value}"
                            id="${result[index].productId}_radio-id_${qtyIndex}" checked="${qtyIndex === 0 ? 'checked' : ''}" tabindex="-1">
                                <label class="radio__label" for="${result[index].productId}_radio-id_${qtyIndex}">
                                <span aria-hidden="true">${SvgImage}</span>
                                    <span aria-hidden="true">&#x2716;</span>
                                    <span>${element}</span></label>
                        </div>`;
                        });
                    }
                    qtyMarkup = `${qtyMarkup}</div>`;

                    if (result[index].actualPrices.length > 0 && result[index].discountedPrices.length > 0 && result[index].discountedPrices[0].value !== result[index].actualPrices[0].value){
                        appendData = `<div class="wcproductQuantity-tile" data-pid='${result[index].productId}' data-shortname='${result[index].shortName}'>
                                    <div class="wcproduct-tileimage">
                                        <img src="${result[index].productImage}" alt="${result[index].productName}">
                                    </div>
                                    ${qtyMarkup}
                                    <div class="wcproduct-name">${result[index].productName}</div>
                                    <div class="wcproduct-discountprice"><span>${result[index].discountedPrices[0].currencyCode}</span>${result[index].discountedPrices[0].value}/yr</div>
                                    <div class="wcproduct-actprice wcproduct-actualprice"><span>${result[index].actualPrices[0].currencyCode}</span>${result[index].actualPrices[0].value}</div>
                                    <button class="removebtn"> Remove </button>
                                    </div>`;
                    }else{
                        appendData = `<div class="wcproductQuantity-tile" data-pid='${result[index].productId}' data-shortname='${result[index].shortName}'>
                                    <div class="wcproduct-tileimage">
                                        <img src="${result[index].productImage}" alt="${result[index].productName}">
                                    </div>
                                    ${qtyMarkup}
                                    <div class="wcproduct-name">${result[index].productName}</div>
                                    <div class="wcproduct-actualprice wcproduct-actprice-withoutstrikethrough"><span>${result[index].actualPrices[0].currencyCode}</span>${result[index].actualPrices[0].value}</div>
                                    <button class="removebtn"> Remove </button>
                                    </div>`;
                    }
                    $(wcproductQuantitySelector).append(appendData);
                });
                const quantitySelector = '#quantitySelector-section';
                $(quantitySelector).show();
                window.location.href = quantitySelector;
                $('.removebtn').on('click', function () {
                    var removepid = $(this).parent()[0].dataset.pid;
                    removeselect(removepid);
                    $(this).parent().remove();
                    if ($(wcTitleElemsSelector).length < 1) {
                        window.location.href = '#wc-tile';
                        $(quantitySelector).hide();
                    }
                });
                $('.wcproduct-qtyselectionlist').on('change','.radio__input', (e) => {
                    const $radioChecked = $(e.target).parent().find('.radio__input:checked');
                    var discountPrice = $radioChecked[0].dataset.discountedprice;
                    var actualPrice = $radioChecked[0].dataset.actualprice;
                    $radioChecked.closest(wcTitleElemsSelector).find('.wcproduct-discountprice').html(discountPrice + '/yr');
                    $radioChecked.closest(wcTitleElemsSelector).find('.wcproduct-actualprice').html(actualPrice);
                });
            } else if (response && response.renderedTemplate) {
                $('.mr-login-form').html(response.renderedTemplate);
                $('.mr-login-form #dwfrm_login_email').focus();
            }
        }
    });
}

function hidepopup() {
    setTimeout(function () {
        $(globalAlertSelector).attr('hidden', 'hidden').html('');
    }, 4000);
}

$(document).ready(function () {
    var newSelectedTier = 'Elite';

    $(wcTitleElemsSelector).each(function () {
        var pid = $(this)[0].dataset.pid;
        var radio = $(this).find('input[type="radio"]');
        radio.each(function (index) {
            var $radioThis = $(this);
            $radioThis.attr('name', pid + '_quantity');
            $radioThis.attr('id', pid + '_radio-id_' + index);
            $radioThis.next().attr('for', pid + '_radio-id_' + index);
        });
    });

    $('#sendproductList').on('click', function () {
        if ($('.wcproduct-selectionlist .wcproduct-tile .selected').length !== 0) {
            var shortArr = [];
            var selectedList = $('.wcproduct-selectionlist .wcproduct-tile.selected');
            selectedList.each(function (index) {
                shortArr.push(selectedList[index].dataset["shortname"]);
            });
           var analytics =  $('#sendproductList').data('analytics');
           analytics.WCStepName = "Type";
           analytics.WCStepSelections = shortArr.join('|'); // "Blank Stare_6|Mercury Head_6|8 Years in the Desert_6
           dataLayer.push(analytics);

            ajaxsend();
        } else {
            $(globalAlertSelector).removeAttr('hidden');
            $(globalAlertSelector).html('Please Select Atleast one Product');
            hidepopup();
        }
    });

    $('#selecttier').on('click', function () {
        $('.tier-preferences').removeClass('h-hidden');
        const selectedTier =  $('.tier-tile-info.active .tierName').text();
        const WCStepName = 'Type';
        WCSignUpStepGTM(WCStepName, selectedTier);
        //$('.change-membership').addClass('h-hidden');
        $('#selecttier').addClass('h-hidden');
        window.scrollTo(0,800);
    });

    $('#gettierpreferences').on('click', function () {
        // Tier and Action URL needs to be updated here
        var url = $(this).attr('data-actionurl');
        var selectedTier = $('.tier-tile-backdrop.active .tierTitle').text();
        setSelectedTier(url, selectedTier);
    });

    function setSelectedTier(url, selectedTier) {
        $.ajax({
            url: url,
            type: 'GET',
            data: { tier: selectedTier },
            dataType: 'JSON',
            success: function (res) {
                if (res && res.tierPreferenceTemplate) {
                    const selectedTier = $('.tier-tile-backdrop.active .tierTitle').text();
                    const WCStepName = 'Type';
                    WCSignUpStepGTM(WCStepName, selectedTier);
                    let templateStr = res.tierPreferenceTemplate.replace('id="quantitySelector-section"', '');
                    $('.tier-preferences').empty().html(templateStr);
                    // $('.tier-preferences').empty().html(res.tierPreferenceTemplate);
                    $('.tier-preferences').removeClass('h-hidden');
                    //$('#gettierpreferences').addClass('h-hidden'); //We will show continue button for event to show the preferences
                    window.scrollTo(0,800);
                }
            }
        });
    }

    $('#quantitySelector-section').on('click', '#sendproductquantity', function () {
        if ($('.wcproduct-selectionlist .wcproduct-tile .selected').length !== 0) {
            var selected_arr = [];
            $(wcTitleElemsSelector).each(function () {
                var shortname = $(this)[0].dataset.shortname;
                var productquantity = $(this).find('input[type="radio"]:checked').val();
                var obj = `${shortname}_${productquantity}`;
                selected_arr.push(obj);
            });

            var gtmData = $(this).data('analytics');
            if (gtmData) {
                gtmData.WCStepName = "wine Quantity" ;
                gtmData.WCStepSelections = selected_arr.join('|');
                dataLayer.push(gtmData);
            }
        
            ProductsQuantityAjax();
        } else {
            $(globalAlertSelector).removeAttr('hidden');
            $(globalAlertSelector).html('Please Select Atleast one Product');
            hidepopup();
        }
    });

 
    $('#quantitySelector-section').on('click', '#sendproductquantityNP', function () {
        var url = $(this).attr('data-actionurl') || '';
        var selectedUrl = $(this).attr('data-selectedurl') || '';
        var selectedTier = $('.tier-tile-backdrop.active .tierTitle').text();
        let wcSignupDataElement = document.getElementById('WcSignupData');

        if (wcSignupDataElement) {
            let wcData = JSON.parse(wcSignupDataElement.dataset.gtm);
            var showStepselections = $('#tierType').val() === 'noPreference' ? true : false;
            if (showStepselections === true) {
                let selectedTier = $('.active .tierName').text();
                if(selectedTier) {
                    wcSignupDataElement.setAttribute('data-wc',selectedTier);
                }
                var gtmData = {
                    event: "WCSignUpStep",
                    WCStepName: "Type",
                    WCName: wcData.WCName,
                    WCId: wcData.WCId,
                    brandId: wcData.brandId,
                    WCMembershipId: wcData.WCMembershipId,
                    sfPersonAccountId: wcData.sfPersonAccountId,
                    WCStepSelections: selectedTier
                };
                if(window.dataLayer) {
                    dataLayer.push(gtmData);
                }
            }
        }

        if((url.length>0) && (selectedTier.length>0)) {
            setSelectedTier(url, selectedTier);
        }
        
        if (selectedUrl.length>0) {
            ProductsQuantityAjax(selectedUrl + selectedTier);
        } else {
            ProductsQuantityAjax();
        }

    })

    $(document).off('click', '.mr-login-form .create-account-section #createAccount');
    $(document).on('click', '.mr-login-form .create-account-section #createAccount', (e) => {
        e.preventDefault();
        e.stopPropagation();
        const actionUrl = e.target.dataset.actionUrl;
        // @ts-ignore
        $.ajax({
            url: actionUrl,
            type: 'GET',
            dataType: 'JSON',
            success: function (res) {
                if (res && res.renderedTemplate) {
                    const selectedTier = 'Create an account';
                    const WCStepName = 'Login';
                    WCSignUpStepGTM(WCStepName, selectedTier);                    
                    $('.mr-register-form').addClass('h-hidden');
                    $('.mr-register-form #dwfrm_login_verificationCode').attr("aria-label","Code");
                    $('.wc-login-form').addClass('h-hidden');
                    $('.mr-register-form .wc-register-form').html(res.renderedTemplate);
                    $('.mr-register-form').removeClass('h-hidden');
                    $('.mr-register-form #dwfrm_profile_customer_firstname').focus();
                }
            }
        });
    });
    $('body').off('click', '.wcproduct-selectionlist .selectbtn');
    $('body').on('click', '.wcproduct-selectionlist .selectbtn', function (e) {
        e.preventDefault();
        if(!$(e.target).hasClass('selectbtn')) {
            return false;
        }
        var preferenceSelectionType = $('[name=preferenceSelectionType]').val();
        var $parent = $(e.target).parent();
        if ($parent.hasClass('selected')) {
            $parent.removeClass('selected');
            $(e.target).text('Select');
            $(e.target).removeClass('selected');
        } else {
            if (preferenceSelectionType === 'singleSelect') {
                const selectionListContainer = $(e.target).closest('.wcproduct-selectionlist');
                const selectedTile = selectionListContainer.find('.wcproduct-tile.selected');
                if (selectedTile) {
                    $(selectedTile).find('.selectbtn').removeClass('selected').text('Select');
                    $(selectedTile).removeClass('selected');
                }
            }
            $parent.addClass('selected');
            $(e.target).addClass('selected');
            $(e.target).text('Deselect');
            $(document).trigger('preferences.changed', [e.target]);
            $('#sendproductquantity button').prop('disabled', false);
        }
    });

    $('.tier-list').on('click', '.tier-tile-backdrop', function () {
        newSelectedTier = $(this).find('.tierTitle').text();
        $('.tier-tile-backdrop').removeClass('active');
        $(this).addClass('active');
    });
});

function WCSignUpStepGTM(WCStepName, selectedTier) {
    let wcSignupDataElement = document.getElementById("WcSignupData");
    if (wcSignupDataElement) {
        let wcData = JSON.parse(wcSignupDataElement.dataset.gtm);
        var gtmData = {
            event: "WCSignUpStep",
            WCStepName: WCStepName,
            WCName: wcData.WCName,
            WCId: wcData.WCId,
            brandId: wcData.brandId,
            WCMembershipId: wcData.WCMembershipId,
            sfPersonAccountId: wcData.sfPersonAccountId,
            WCStepSelections: selectedTier
        };
        if (window.dataLayer) {
            dataLayer.push(gtmData);
        }
    }
}
