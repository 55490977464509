export default function (HarmonyModal) {
    var oldPrefrences = '';
    var selectedpreferenceName = '';
    var newPreferenceName = '';
    class managePreferenceModal extends HarmonyModal {
        init() {
            super.init();
            this.getOldPrefrences = () => oldPrefrences;
            /**
             * @param {any} name
             */
            this.setOldPrefrences = (name) => { 
                oldPrefrences = name; 
            };

           
            $('.managePreferenceModal').off('click').on('click', '.tier-tile-backdrop', (e) => {
                let $this = $(e.currentTarget);
                let newSelectedTier = $($this).find('.tierTitle').text();
                var teirPrefURL = $('#tierPreferenceManage').val();
                this.updateOldPrefrences($($this).data('oldtier'));
                $('.tier-tile-backdrop').removeClass('active');
                // selectedpreferenceName = '';
                $($this).addClass('active');
                this.generatePrefrence(teirPrefURL, newSelectedTier);
                newPreferenceName = $('.tier-tile-backdrop.active .tierTitle').text() + '-' + $($this).data('selectedprefrence');
                $('.updateMembership').prop('disabled', true);
                if (oldPrefrences.replace(/[^a-zA-Z0-9]/g, '') === newPreferenceName.replace(/[^a-zA-Z0-9]/g, '')) {
                    $('.cancelMembershipAlert').prop('disabled', false);
                } else {
                    $('.cancelMembershipAlert').prop('disabled', true);
                }
            });
            $(document).bind('preferences.changed', (e, status) => {
                this.updateOldPrefrences($('.tier-tile-backdrop.active').data('oldtier'));
                $('.updateMembership').prop('disabled', false);
                newPreferenceName = $('.tier-tile-backdrop.active .tierTitle').text() + '-' + $(status).parent().data('pid').replaceAll(' ','');
                if (oldPrefrences.replace(/[^a-zA-Z0-9]/g, '') === newPreferenceName.replace(/[^a-zA-Z0-9]/g, '')) {
                    $('.cancelMembershipAlert').prop('disabled', false);
                    $('.updateMembership').prop('disabled', true);

                } else {
                    $('.cancelMembershipAlert').prop('disabled', true);
                    $('.updateMembership').prop('disabled', false);

                }
            });
        }

        updateOldPrefrences(preferences) {
            this.setOldPrefrences(preferences);
        }
        gePrefrences() {
            return this.getOldPrefrences();
        }
        closeModal() {
            const changeMade = oldPrefrences;
            if (changeMade.length && $('.updateMembership').length){
                // this.back();
                $('.b-dialog-header .b-dialog-close').hide();
                $('.managePreferenceModal .memership-tile').hide();
                $('.managePreferenceModal .memership-updated').hide();
                $('.managePreferenceModal .memership-pending').removeClass('h-hidden');
                $('.b-dialog-footer').addClass('h-hidden');
                return false;
            }
            else {
                window.location.reload();
            }
            super.closeModal();
        }

        show() {
            this.getById('onlypreference', (/** @type {modal} */ wineclub) => wineclub
                .showModal({
                    attributes: {
                        'data-tau-unique': 'open_op_dialog'
                    }
                }));
            var membershipURL = $('#onlyPreferenceManage').val();
            var teirPrefURL = $('#tierPreferenceManage').val();

            var currWcmembershipObj = $(this.cachedTemplates.template).find('.prefernceMemObj').val();
            var wcmembershipObj = { wcmembershipObj: currWcmembershipObj };
            
            var analytics = this.data('analytics');
            if(analytics) {
                var gtmData = {
                    event:  "WCManage",
                    WCAction: "Initiate",
                    WCName: analytics.WCName,
                    WCId: analytics.WCId,
                    brandId: analytics.brandId,
                    WCMembershipId: analytics.WCMembershipId,
                    sfPersonAccountId: analytics.sfPersonAccountId,
                    WCSubgroup: analytics.WCSubgroup
                }
                dataLayer.push(gtmData);
            }
            // @ts-ignore
            $.ajax({
                url: membershipURL,
                data: wcmembershipObj,
                type: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Accept: 'application/json',
                    'x-requested-with': 'XMLHttpRequest'
                },
                success: (res) => {
                    setTimeout(() => {
                        $('.membership-loader').hide();
                        $('.renderopTemplate').html(res.onlyPreferenceHtml);
                        this.generatePrefrence(teirPrefURL, res.tierToManage);
                        selectedpreferenceName = res.selectedpreferenceName;
                    },
                    0);
                },
                error: function (error) {
                    console.log(error);
                }
            });
        }
        generatePrefrence (url, newSelectedTier) {
            $.ajax({
                url: url,
                type: 'GET',
                data: { tier: newSelectedTier},
                dataType: 'JSON',
                success: function (res) {
                    if (res && res.tierPreferenceTemplate) {
                        $('.tier-preferences').empty().html(res.tierPreferenceTemplate);
                        $('.b-dialog-footer').removeClass('h-hidden');
                        $('.tier-preferences .wcproduct-tile').each(function(ele,ind) {
                            // console.log($(ind).data('pid'));
                            if(($(ind).data('pid').replaceAll(' ','') === selectedpreferenceName)
                                && (oldPrefrences.replace(/[^a-zA-Z0-9]/g, '') === newPreferenceName.replace(/[^a-zA-Z0-9]/g, ''))) {
                                $(ind).addClass('selected');
                                $(ind).find('.selectbtn').addClass('selected');
                                $(ind).find('.selectbtn').text('Deselect');
                            }
                        })
                        // $('#gettierpreferences').addClass('h-hidden');
                    }
                }
            });
        }
        updateMembership() {
            let updatePrefURL = $('#updateMembership').val();
            var tier = $('.tier-tile-backdrop.active').data('tier');
            tier = tier.replace(/[^a-zA-Z0-9]/g, '');
            let preferences = $('.wcproduct-tile.selected').data('pid');
            let _newPrefrences = `${tier}-${preferences}`.replaceAll(' ','');
            let _old_preferences = this.gePrefrences().replaceAll(' ','');
            let preferencesObj = {
                oldPreference: _old_preferences,
                newPreference: _newPrefrences
            };
            var analytics = this.data('analytics');
            if(analytics) {
                var gtmData = {
                    event:  "WCManage",
                    WCAction: "Update",
                    WCName: analytics.WCName,
                    WCId: analytics.WCId,
                    brandId: analytics.brandId,
                    WCMembershipId: analytics.WCMembershipId,
                    sfPersonAccountId: analytics.sfPersonAccountId,
                    WCSubgroup: analytics.WCSubgroup
                }
                dataLayer.push(gtmData);
            }
            this.updateOldPrefrences('');
            $.ajax({
                url: updatePrefURL,
                type: 'POST',
                data: preferencesObj,
                dataType: 'JSON',
                success: function (res) {
                    $('.managePreferenceModal .memership-tile').hide();
                    $('.managePreferenceModal .memership-pending').hide();
                    $('.managePreferenceModal .memership-updated').show();
                    $('.b-dialog-footer').addClass('h-hidden');
                    oldPrefrences =''; 
                }
            });
        }

        cancelMembership() {
            var url = $('.cancelMembershipURL').val();
            var memGrp = $('#parentTierName').val();
            var analytics = this.data('analytics');
            let membershipCancellationReason = $('.membership-cancel-reason').find(":selected").val();
            if(analytics) { //analytics to be enabled on GTM
                var gtmData = {
                    event: 'WCManage',
                    WCAction: 'Cancel',
                    WCName: analytics.WCName,
                    WCId: analytics.WCId,
                    brandId: analytics.brandId,
                    WCMembershipId: analytics.WCMembershipId,
                    sfPersonAccountId: analytics.sfPersonAccountId,
                    WCSubgroup: analytics.WCSubgroup
                }
                dataLayer.push(gtmData);
            }

            $.ajax({
                url: url,
                data: {
                    memCustGrp: memGrp,
                    membershipCancellationReason
                },
                type: 'GET',
                success: function (res) {
                    $('.renderopTemplate').html(res.cancelMembershipSuccess);
                    oldPrefrences =''; 
                },
                error: function (error) {
                    console.log(error);
                }
            });
        }

        cancelMembershipAlert() {
            var url = $('.cancelMembershipAlertURL').val();
            var parentTierName =  $('.tier-tile-backdrop.active').data('tier').replace(/[^a-zA-Z0-9]/g, '') + '-' + $('.wcproduct-tile.selected').data('pid').replaceAll(' ','');
            $.ajax({
                url: url,
                type: 'GET',
                data: {
                    parentTierName: parentTierName
                },
                success: function (res) {
                    $('.tier-preferences').addClass('h-hidden');
                    $('.mem-info').addClass('h-hidden');
                    $('.b-dialog-footer').addClass('h-hidden');

                    $('.renderopTemplate').html(res.cancelMembershipAlert);
                },
                error: function (error) {
                    console.log(error);
                }
            });
        }

        back() {
            window.location.reload();
        }
    }

    return managePreferenceModal;
}
