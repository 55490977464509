import { showErrorLayout } from 'widgets/toolbox/util';
export default function (HarmonyModal) {
    class WineClubModal extends HarmonyModal {
        init() {
            super.init();

        }

        closeModal() {
            let url = $('.skipReturn').attr('href');
            if (url && url !== '') {
                window.location.href = url;
            }

            super.closeModal();
        }

        show() {
            this.getById('wineclub', (/** @type {modal} */ wineclub) => wineclub
                .showModal({
                    attributes: {
                        'data-tau-unique': 'open_wineclub_dialog'
                    }
                }));

            let url = this.data('skipurl');
            var analytics = this.data('analytics');

            if (url) {
                $.ajax({
                    url: url,
                    type: 'GET',
                    success: function (res) {
                        $('.renderTemplate').html(res.renderedTemplate);
                        if (analytics) {
                            var gtmData = {
                                event: "WCSkip",
                                WCAction: "Initiate",
                                WCName: analytics.WCName,
                                WCId: analytics.WCName,
                                brandId: analytics.brandId,
                                WCMembershipId: analytics.WCMembershipId,
                                sfPersonAccountId: analytics.sfPersonAccountId,
                                WCSubgroup: analytics.WCSubgroup
                            };
                            dataLayer.push(gtmData);
                        }
                    },
                    error: function (XMLHttpRequest, textStatus,err) {
                        showErrorLayout(err);
                    }
                });
            }
        }

        skip() {
            let url = $('#skipUrl').val();
            var analytics = this.data('analytics');

            $.ajax({
                url: url,
                type: 'GET',
                success: function (res) {
                    $('.renderTemplate').html(res.renderedTemplate)
                    
                    if (analytics) {
                        var gtmData = {
                            event:  "WCSkip",
                            WCAction: "Skip",
                            WCName: analytics.WCName,
                            WCId: analytics.WCName,
                            brandId: analytics.brandId,
                            WCMembershipId: analytics.WCMembershipId,
                            sfPersonAccountId: analytics.sfPersonAccountId,
                            WCSubgroup: analytics.WCSubgroup
                        };
                        dataLayer.push(gtmData);
                    }
                },
                error: function (XMLHttpRequest, textStatus,err) {
                    showErrorLayout(err);
                }
            });
        }
    }

    return WineClubModal;
}
