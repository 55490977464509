// @ts-ignore
export default function (HarmonyModal) {
    let parentBottleId = '';
    let pList = '';
    let swapBoolean = '';
    let tierName = '';
    let productTile = '';
    let parentElementIndex = '';

    class SwapBottlesModal extends HarmonyModal {
        init() {
            super.init();
            window.sessionStorage.removeItem('swapBottlesDataObject');
        }

        closeModal() {
           super.closeModal();
        }

        // @ts-ignore
        show(e) {
            // @ts-ignore
            this.getById('swapbottles', (/** @type {modal} */ swapbottles) => swapbottles
                .showModal({
                    attributes: {
                        'data-tau-unique': 'open_swap-bottles_dialog'
                    }
                }));
                $(".b-swap-dialog-close").focus();
                var swapBottlesURL = document.getElementById('swapBottlesURL');
                if (swapBottlesURL) {
                    var url = swapBottlesURL.value;
                    $.ajax({
                        url: url,
                        type: 'get',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            Accept: 'application/json',
                            'x-requested-with': 'XMLHttpRequest'
                        },
                        success: function (res) {
                            $('.swap-dialog-inner').html(res);
                            $('.swap-dialog-inner button.b-product_tile-cta.m-short').remove();
                            $('.swap-dialog-inner div.b-product_quantity').remove();
                            const swapBtn = '<button class=\'b-product_tile-cta m-short swap-bottles-modal-btn\'>SWAP</button>';
                            $(swapBtn).insertAfter('.swap-dialog-inner .b-product_tile-quantity');
                            return false;
                        },
                        error: function (err) {
                            console.log(err);
                        }
                    });
                }
            
            if (e && e.length) {
                // @ts-ignore
                parentBottleId = $(e.els).prev().attr('item');
                parentElementIndex = $(e.els).attr('data-index');
                // @ts-ignore
                pList = $(e.els).prev().attr('list');
                // @ts-ignore
                swapBoolean = $(e.els).prev().attr('swapallowed');
                tierName = $('#swapTierID').val();
                productTile = $('.swap-product-tile:nth-child('+ parentElementIndex +')');
            }
            $(document).off('click', '.swap-bottles-modal-btn');
            $(document).on('click', '.swap-bottles-modal-btn', (event) => {
                event.preventDefault();
                const swappedBottleId = $(event.target)
                    .parentsUntil(
                        'div.b-product_tile.m-grid.m-page_designer.m-static'
                    )
                    .attr('data-product-id');
                if(productTile) {
                    let carouselItem = $(event.target).closest('.b-carousel-slide');
                    let imageEle = carouselItem.find('picture img');
                    productTile.find('img').attr('src', imageEle.attr('src')).attr('alt', imageEle.attr('alt'));
                    productTile.find('.wcproduct-name').html(carouselItem.find('.wcproduct-name').html());
                    let oldPriceSrEle = carouselItem.find('.b-product_price-value.m-old .sr-only-element').html();
                    let newPriceSrEle = carouselItem.find('.b-product_price-value.m-sales .sr-only-element').html();
                    productTile.find('.wcproductprice .sr-only-element').first().html(oldPriceSrEle);
                    productTile.find('.wcproductprice .sr-only-element').last().html(newPriceSrEle);
                    productTile.find('.wcproductprice .b-product_price-value.m-old').html(carouselItem.find('.b-product_price-value.m-old span:nth-child(2) div').text().replace('$', ''));
                    let parentSalePriceElement = productTile.find('.wcproductprice .b-product_price-value.m-sales');
                    let parentBottlePrice = parseFloat(parentSalePriceElement.html()).toFixed(2);
                    let swappedElementSalePrice = carouselItem.find('.b-product_price-value.m-sales').attr('content');
                    parentSalePriceElement.html(parseFloat(swappedElementSalePrice).toFixed(2).toString());
                    let swapBottlesData = window.sessionStorage.getItem('swapBottlesDataObject') ?  JSON.parse(window.sessionStorage.getItem('swapBottlesDataObject')) : [];
                    if(swapBottlesData.length) {
                        let hasProductInSession = swapBottlesData.findIndex(bottleData => bottleData.index === parentElementIndex);
                        if(hasProductInSession >= 0) {
                            swapBottlesData[hasProductInSession].swappedProductId = swappedBottleId;
                        } else {
                            swapBottlesData.push({
                                originalProductId: parentBottleId,
                                swappedProductId: swappedBottleId,
                                quantity: 1,
                                index : parentElementIndex
                            });
                        }
                        window.sessionStorage.setItem('swapBottlesDataObject', JSON.stringify(swapBottlesData));
                    } else {
                        swapBottlesData.push({
                            originalProductId : parentBottleId,
                            swappedProductId: swappedBottleId,
                            quantity: 1,
                            index: parentElementIndex
                        });
                        window.sessionStorage.setItem('swapBottlesDataObject', JSON.stringify(swapBottlesData));
                    }

                    if ($('.b-product_tile-cta.saveShipment-cta').is('[disabled]') ) {
                        $('.b-product_tile-cta.saveShipment-cta').removeAttr("disabled");
                    }
                    let analytic = JSON.parse($('#WcSignupData').val());
                    let gtmData = {
                        event: "WCCustomize",
                        WCAction: "Swap",
                        WCName: analytic.WCName,
                        WCId: analytic.WCId,
                        brandId: analytic.brandId,
                        WCMembershipId: analytic.WCMembershipId,
                        sfPersonAccountId: analytic.sfPersonAccountId,
                        WCSubgroup: analytic.WCSubgroup,
                        WCSwapOld: parentBottleId,
                        WCSwapNew: swappedBottleId
                    };
                    dataLayer.push(gtmData);    
                }

                this.closeModal();    
            });
        }
    }

    return SwapBottlesModal;
}
