export default function (HarmonyModal) {
    class ManageEquinoxModal extends HarmonyModal {
        init() {
            super.init();
            $('.manageNoPreferenceModal').off('click').on('click', '.tier-tile-backdrop', (e) => {
                const $this = $(e.currentTarget);
                $('.tier-tile-backdrop').removeClass('active');
                $($this).addClass('active');
                var tier = $('.tier-tile-backdrop.active').data('tier');
                var oldTier = $('#old-tier').val();
                // @ts-ignore
                var newTier = `${tier}`.replaceAll(' ', '');
                newTier = newTier.replace(/[^a-zA-Z0-9]/g, '');
                // @ts-ignore
                oldTier = `${oldTier}`.replaceAll(' ', '');
                // @ts-ignore
                oldTier = oldTier.replace(/[^a-zA-Z0-9]/g, '');
                if (oldTier === newTier) {
                    $('.cancelMembershipAlert').prop('disabled', false);
                    $('.updateMembership').prop('disabled', true);
                } else {
                    $('.updateMembership').prop('disabled', false);
                    $('.cancelMembershipAlert').prop('disabled', true);
                }
            });
        }

        closeModal() {
            const manage = $('#successManage').val();
            if (manage && manage === 'true') {
                this.back();
            }
            super.closeModal();
        }

        show(e) {
            this.getById('equinox', (/** @type {modal} */ wineclub) => wineclub
                .showModal({
                    attributes: {
                        'data-tau-unique': 'open_equinox_dialog'
                    }
                }));
            var analytics = this.data('analytics');
            if (analytics) {
                var gtmData = {
                    event: 'WCManage',
                    WCAction: 'Initiate',
                    WCName: analytics.WCName,
                    WCId: analytics.WCId,
                    brandId: analytics.brandId,
                    WCMembershipId: analytics.WCMembershipId,
                    sfPersonAccountId: analytics.sfPersonAccountId,
                    WCSubgroup: analytics.WCSubgroup
                };
                    // @ts-ignore
                dataLayer.push(gtmData);
            }

            var membershipURL = $('#noPreferenceManage').val();
            // var currWcmembershipObj = $('.nopreferenceObj').val();
            var currWcmembershipObj= [];
            var wcmembershipObj = [];
            if(e) {
                currWcmembershipObj = JSON.stringify( e.data('nopreferenceobj'));
                wcmembershipObj = { wcmembershipObj: currWcmembershipObj };
            } else {
                return false;
            }

            // @ts-ignore
            $.ajax({
                url: membershipURL,
                data: wcmembershipObj,
                type: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Accept: 'application/json',
                    'x-requested-with': 'XMLHttpRequest'
                },
                // @ts-ignore
                success: (res) => {
                    setTimeout(function() {
                        $('.membership-loader').hide();
                        $('.rendernpTemplate').html(res.noPreferenceHtml); },
                    0);
                },
                // @ts-ignore
                error: function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error);
                }
            });
        }

        cancelMembership() {
            $('.membership-des').hide();
            var url = $('.cancelMembershipURL').val();
            var memGrp = $(this.cachedTemplates.template).find('.memCustomerGroup').val();
            var analytics = this.data('analytics');
            let membershipCancellationReason = $('.membership-cancel-reason').find(":selected").val();
            // @ts-ignore
            $.ajax({
                url: url,
                data: {
                    memCustGrp: memGrp,
                    membershipCancellationReason: membershipCancellationReason
                 },
                type: 'GET',
                success: function (res) {
                    $('.membership-loader').hide();
                    $('.rendernpTemplate').html(res.cancelMembershipSuccess);
                    if (analytics) {
                        var gtmData = {
                            event: 'WCManage',
                            WCAction: 'Cancel',
                            WCName: analytics.WCName,
                            WCId: analytics.WCId,
                            brandId: analytics.brandId,
                            WCMembershipId: analytics.WCMembershipId,
                            sfPersonAccountId: analytics.sfPersonAccountId,
                            WCSubgroup: analytics.WCSubgroup
                        };
                        // @ts-ignore
                        dataLayer.push(gtmData);
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        }

        cancelMembershipAlert() {
            $('.membership-des').hide();
            var url = $('.cancelMembershipAlertURL').val();
            var parentTierName = $('#old-tier').val();
            $('.action-btn').hide();
            // @ts-ignore
            $.ajax({
                url: url,
                type: 'GET',
                data: {
                    parentTierName: parentTierName
                },
                success: function (res) {
                    $('.membership-loader').hide();

                    $('.rendernpTemplate').html(res.cancelMembershipAlert);
                },
                error: function (error) {
                    console.log(error);
                }
            });
        }

        updateMembership() {
            var updatePrefURL = $('#updateMembership').val();
            var tier = $('.tier-tile-backdrop.active').data('tier');
            var oldTier = $('#old-tier').val();
            // @ts-ignore
            var newTier = `${tier}`.replaceAll(' ', '');
            newTier = newTier.replace(/[^a-zA-Z0-9]/g, '');
            // @ts-ignore
            oldTier = `${oldTier}`.replaceAll(' ', '');
            // @ts-ignore
            oldTier = oldTier.replace(/[^a-zA-Z0-9]/g, '');
            var preferencesObj = {
                oldPreference: oldTier,
                newPreference: newTier
            };
            var analytics = this.data('analytics');
            // @ts-ignore
            $.ajax({
                url: updatePrefURL,
                type: 'POST',
                data: preferencesObj,
                dataType: 'JSON',
                success: function (res) {
                    // window.location.reload();
                    $('.membership-loader').hide();
                    $('.tier-info').hide();
                    $('.action-btn').hide();
                    $('.memership-updated').show('');
                    if (analytics) {
                        var gtmData = {
                            event: 'WCManage',
                            WCAction: 'Update',
                            WCName: analytics.WCName,
                            WCId: analytics.WCId,
                            brandId: analytics.brandId,
                            WCMembershipId: analytics.WCMembershipId,
                            sfPersonAccountId: analytics.sfPersonAccountId,
                            WCSubgroup: analytics.WCSubgroup
                        };
                        // @ts-ignore
                        dataLayer.push(gtmData);
                    }
                }
            });
        }

        back() {
            window.location.reload();
        }
    }

    return ManageEquinoxModal;
}
