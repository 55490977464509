/* eslint-disable sonarjs/no-duplicate-string */
// import HarmonyModal from 'harmony/global/HarmonyModal';

// @ts-ignore
import { showErrorLayout } from 'widgets/toolbox/util';

export default function (HarmonyModal) {
    var plistID = '';
    class RescheduleModal extends HarmonyModal {
        init() {
            super.init();
            /** @type {never[]} */
            this.ele = [];
        }

        // @ts-ignore
        show(e, id) {
            if (id) {
                plistID = id.target.attributes['data-plistid'].value;
            }

            // @ts-ignore
            this.getById('reschedule', (/** @type {modal} */ reschedule) => reschedule
                .showModal({
                    attributes: {
                        'data-tau-unique': 'open_reschedule_dialog'
                    }
                }));
            const minDate = $('#shipmentEditForm > p.edit-shipment-last-date').text();
            const applicableDate = minDate.substring(minDate.length - 10).split('/').reverse();
            const finalDate = applicableDate[0] + '-' + applicableDate[2] + '-' + applicableDate[1];
            $('#datePickerId').attr('min', finalDate);
            const hiddenShipmentDate = $('#hiddenShipmentDate').text();
            $('#datePickerId').val(hiddenShipmentDate);

            var analytics = this.data('analytics');
            if (analytics) {
                var gtmData = {
                    event: 'WCReschedule',
                    WCAction: 'Initiate',
                    WCName: analytics.WCName,
                    WCId: analytics.WCName,
                    brandId: analytics.brandId,
                    WCMembershipId: analytics.WCMembershipId,
                    sfPersonAccountId: analytics.sfPersonAccountId,
                    WCSubgroup: analytics.WCSubgroup
                };
                this.eventBus().emit('gtm.reshedule.shipment', gtmData);
            }
        }

        reschedule() {
            var shipmentDate = $('#datePickerId').val();
            var url = $('#shipmentEditForm').attr('action');
            var analytics = this.data('analytics');

            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: 'date=' + shipmentDate + '&plistID=' + plistID,
                async: true,
                success: async function (data) {
                    if (typeof data.newShipmentDate === 'string') {
                        const date = data.newShipmentDate
                            .split('T')[0]
                            .split('-')
                            .reverse();
                        const hiddenShipmentDate = data.newShipmentDate.split('T')[0];
                        const dd = date[1] + '/' + date[0] + '/' + date[2];
                        const heading = `<h2 class='b-user_greeting-message'>${data.successMessage}</h2>`;
                        const para = `<p class='info-title'>${dd}
                    </p>`;
                        await $('#shipment-date-form-heading-container').append(
                            heading
                        );
                        await $('#shipment-date-form-para-container').append(para);
                        await $('#form-before-submitting').hide();
                        await $('#form-after-submitting').removeClass('edit-shipment-hidden');
                        await $(('form-modal-container-inner')).html();
                        await $('#hiddenShipmentDate').empty().append(hiddenShipmentDate);
                        
                            if(analytics) {
                                var gtmData = {
                                    event: 'WCReschedule',
                                    WCAction: 'Reschedule',
                                    WCName: analytics.WCName,
                                    WCId: analytics.WCName,
                                    brandId: analytics.brandId,
                                    WCMembershipId: analytics.WCMembershipId,
                                    sfPersonAccountId: analytics.sfPersonAccountId,
                                    WCSubgroup: analytics.WCSubgroup
                                };
                                dataLayer.push(gtmData);
                            }
                    }
                    if (data.errorMessage) {
                        $('.btn-edit-form-main').prop('disabled', true);
                        $('.shipment-error-message').empty().append(data.errorMessage);
                        $('#datePickerId').on('change', function () {
                            $('.btn-edit-form-main').prop('disabled', false);
                        });
                        var gtmData = {
                            "event": "error",
                            "errorText": data.errorMessage
                        }
                        dataLayer.push(gtmData); 
                    }
                },
                error: function (XMLHttpRequest, textStatus,err) {
                    showErrorLayout(err);
                }
            });
        }

        closeRescheduleModal() {
            super.closeModal();
            window.location.reload();
        }
    }

    return RescheduleModal;
}
