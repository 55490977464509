$(document).ready(function () {
    $('#dwfrm_waitListForm_newsletterJoin').change(function () {
        var form = $('#waitlistformid :input').map(function (index, elm) {
            return elm.name;
        });
        if (this.checked) {
            var gtmData = {
                event: 'emailSubscribe',
                formName: 'Newsletter',
                formAction: 'Opt-In',
                formStatus: 'Success',
                fieldEngagement: 'dwfrm_waitListForm_newsletterJoin'

            };
            dataLayer.push(gtmData);
        }
    });
    $('#waitlistformid').on('submit', function (e) {
        e.preventDefault();
        var url = $('#waitlistformid').attr('action');
        var tierName = $('#waitlistformid').attr('data-tierName');
        var formname = tierName ? tierName.concat(' ', 'Waitlist') : 'null';
        var form = $('#waitlistformid :input').map(function (index, elm) {
            return elm.name;
        });
        window.dataLayer.push({
            event: 'form-submit',
            formName: formname,
            formAction: 'Submit',
            formStatus: 'First Interaction',
            fieldEngagement: form.toArray().join('>')
        });

        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json',
            async: true,
            data: $('.myform').serialize(),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
                'x-requested-with': 'XMLHttpRequest'
            },
            success: function (res) {
                if (res.success === false) {
                    $("#waitListErrorMsg").removeClass('h-hidden');
                    window.dataLayer.push({
                        event: 'form-submit',
                        formName: formname,
                        formAction: 'Submit',
                        formStatus: 'Error: Form Validation',
                        errorMessage: res.errorMessage
                    });
                } else if (res.redirectUrl) {
                    window.dataLayer.push({
                        event: 'form-submit',
                        formName: formname,
                        formAction: 'Submit',
                        formStatus: 'Success',
                        fieldEngagement: form.toArray().join('>')
                    });
                    window.location.href = res.redirectUrl;
                }
            }
        });
    });
    if($(".welcomeMsg").hasClass("waitlistsuceess") === true){
        window.dataLayer.push({
            event: 'form-submit',
            formName: 'HeritageClub Waitlist',
            formAction: 'Submit',
            formStatus: 'Success',
            fieldEngagement: "dwfrm_waitListForm_firstName>dwfrm_waitListForm_lastName>dwfrm_waitListForm_email>dwfrm_waitListForm_phone>dwfrm_waitListForm_newsletterJoin>>tier"
        });
    }
});
