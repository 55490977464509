export default function (HarmonyModal) {
    class ManageMilkRunModal extends HarmonyModal {
        init() {
            super.init();
            $(document).on('click', '.edit-milkRun .qty-available .radio__input', function (e) {
                e.preventDefault();
                const $radioChecked = $(this).parent().find('.radio__input:checked');
                var discountPrice = $radioChecked[0].dataset.discountedprice;
                var actualPrice = $radioChecked[0].dataset.actualprice;
                $('.edit-milkRun .radio__label.selected').removeClass('selected');
                $radioChecked.closest('.quantity-options').find('.radio__label').addClass('selected');
                $radioChecked.closest('.edit-milkRun').find('.price').html(discountPrice + '/yr');
                $radioChecked.closest('.edit-milkRun').find('.disc-price s').html(actualPrice);
                $radioChecked.closest('.edit-milkRun').find('#UpdateMilkRunPrefQty').val($radioChecked.val());
            });
        }

        closeModal() {
            const manage = $('#successManage').val();
            if (manage && manage === 'true') {
                this.back();
            }
            super.closeModal();
        }

        show() {
            this.getById('milkRun', (/** @type {modal} */ wineclub) => wineclub
                .showModal({
                    attributes: {
                        'data-tau-unique': 'open_milkRun_dialog'
                    }
                }));
            var analytics = this.data('analytics');
            if(analytics) {
                var gtmData = {
                    event:  "WCManage",
                    WCAction: "Initiate",
                    WCName: analytics.WCName,
                    WCId: analytics.WCId,
                    brandId: analytics.brandId,
                    WCMembershipId: analytics.WCMembershipId,
                    sfPersonAccountId: analytics.sfPersonAccountId,
                    WCSubgroup: analytics.WCSubgroup
                 }
                 dataLayer.push(gtmData);
            }

            var milkRunMembershipURL = $('#milkRunMembershipURL').val();
            var currWcmembershipObj = $(this.cachedTemplates.template).find('.wcmembershipObj').val();
            var wcmembershipObj = { wcmembershipObj: currWcmembershipObj };
            // @ts-ignore
            $.ajax({
                url: milkRunMembershipURL,
                data: wcmembershipObj,
                type: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Accept: 'application/json',
                    'x-requested-with': 'XMLHttpRequest'
                },
                success: function (res) {
                    $('.renderTemplate').html(res.milkRunMembershipHtml);
                },
                error: function (error) {
                    console.log(error);
                }
            });
        }

        updateMembership() {
            let UpdateMilkRunPrefQtyURL = $('#UpdateMilkRunPrefQtyURL').val();
            let prefName = $(this.cachedTemplates.template).find('.memCustomerGroup').val();
            let qty = parseInt($('#UpdateMilkRunPrefQty').val());

            var analytics = this.data('analytics');
            if(analytics) {
                var gtmData = {
                    event:  "WCManage",
                    WCAction: "Update",
                    WCName: analytics.WCName,
                    WCId: analytics.WCId,
                    brandId: analytics.brandId,
                    WCMembershipId: analytics.WCMembershipId,
                    sfPersonAccountId: analytics.sfPersonAccountId,
                    WCSubgroup: analytics.WCSubgroup
                 }
                 dataLayer.push(gtmData);
            }

            $.ajax({
                url: UpdateMilkRunPrefQtyURL,
                type: 'POST',
                data: {
                    preferenceName: prefName,
                    quantity: qty
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Accept: 'application/json',
                    'x-requested-with': 'XMLHttpRequest'
                },
                success: function () {
                    window.location.reload();
                },
                error: function (error) {
                    console.log(error);
                }
            });
        }

        cancelMembership() {
            var url = $('.cancelMembershipURL').val();
            var memGrp = $(this.cachedTemplates.template).find('.memCustomerGroup').val();
            var analytics = this.data('analytics');
            let membershipCancellationReason = $('.membership-cancel-reason').find(":selected").val();
            if(analytics) {
                var gtmData = {
                    event:  "WCManage",
                    WCAction: "Cancel",
                    WCName: analytics.WCName,
                    WCId: analytics.WCId,
                    brandId: analytics.brandId,
                    WCMembershipId: analytics.WCMembershipId,
                    sfPersonAccountId: analytics.sfPersonAccountId,
                    WCSubgroup: analytics.WCSubgroup
                 }
                 dataLayer.push(gtmData);
            }

            $.ajax({
                url: url,
                data: {
                    memCustGrp: memGrp,
                    membershipCancellationReason: membershipCancellationReason
                },
                type: 'GET',
                success: function (res) {
                    $('.renderTemplate').html(res.cancelMembershipSuccess);
                },
                error: function (error) {
                    console.log(error);
                }
            });
        }

        cancelMembershipAlert() {
            var url = $('.cancelMembershipAlertURL').val();
            var parentTierName = $('.memCustomerGroup').val();
            $.ajax({
                url: url,
                type: 'GET',
                data: {
                    parentTierName: parentTierName
                },
                success: function (res) {
                    $('.renderTemplate').html(res.cancelMembershipAlert);
                },
                error: function (error) {
                    console.log(error);
                }
            });
        }

        back() {
            window.location.reload();
        }
    }

    return ManageMilkRunModal;
}
