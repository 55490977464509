// @ts-ignore
export default function (HarmonyModal) {
    class OverrideBatchModal extends HarmonyModal {
        init() {
            super.init();
            window.sessionStorage.removeItem('overrideBatchData');
        }

        closeModal() {
            super.closeModal();
            window.sessionStorage.removeItem('overrideBatchData');
        }

        // @ts-ignore
        show(e) {
            // @ts-ignore
            this.getById('overridebottles', (/** @type {modal} */ overridebottles) => overridebottles
                .showModal({
                    attributes: {
                        'data-tau-unique': 'open_override-batch_dialog'
                    }
                }));
            $(".b-swap-dialog-close").focus();
            this.getAllProducts();

            $(document).off('keyup', '#override-header-search-input');
            $(document).on('keyup', '#override-header-search-input', (event) => {
                if(event.target.value) {
                    $('#overrideClearButton').addClass('m-visible');
                } else {
                    $('#overrideClearButton').removeClass('m-visible');
                    this.getAllProducts(null, null);
                }
            });

            $(document).off('click', '#overrideClearButton');
            $(document).on('click', '#overrideClearButton', (event) => {
                $('#override-header-search-input').val('');
                $('#overrideClearButton').removeClass('m-visible');
                var overrideBatchData = JSON.parse(window.sessionStorage.getItem('overrideBatchData')) || [];
                this.getAllProducts(null, overrideBatchData);
            });

            $(document).off('click', '#overrideSearchSubmit');
            $(document).on('click', '#overrideSearchSubmit', (event) => {
                event.preventDefault();
                let searchInput = $('#override-header-search-input').val();
                if(!searchInput) {
                    return false;
                } else {
                    var overrideBatchData = JSON.parse(window.sessionStorage.getItem('overrideBatchData')) || [];
                    this.getAllProducts(searchInput, overrideBatchData);
                }

            });

            $(document).off('keyup', '.override-product_quantity-input');
            $(document).on('keyup', '.override-product_quantity-input', (event) => {
                var overrideBatchData = JSON.parse(window.sessionStorage.getItem('overrideBatchData')) || [];
                if (overrideBatchData.length) {
                    let productIndex = overrideBatchData.findIndex((product) => { return product.pid === event.target.dataset.pid; });
                    if(productIndex === -1) {
                        overrideBatchData.push({
                            pid: event.target.dataset.pid,
                            quantity: event.target.value
                        });
                    } else {
                        if(event.target.value) {
                            overrideBatchData[productIndex].quantity = event.target.value;
                        } else {
                            overrideBatchData.splice(productIndex, 1);
                        }
                    }
                    window.sessionStorage.setItem('overrideBatchData', JSON.stringify(overrideBatchData));
                } else {
                    overrideBatchData.push({
                        pid: event.target.dataset.pid,
                        quantity: event.target.value
                    });
                    window.sessionStorage.setItem('overrideBatchData', JSON.stringify(overrideBatchData));
                }

                if(overrideBatchData.length) {
                    $('.override-batch_submit-button').prop("disabled", false);
                } else {
                    $('.override-batch_submit-button').prop("disabled", true);
                }
            });

            $(document).off('click', '.override-batch_submit-button');
            $(document).on('click', '.override-batch_submit-button', (event) => {
                var overrideBatchData = JSON.parse(window.sessionStorage.getItem('overrideBatchData')) || [];
                if (overrideBatchData.length) {
                    var submitUrl = event.target.dataset.actionUrl;
                    var tierId = event.target.dataset.overrideTierId;
                    var wineClubProdList = event.target.dataset.overridePlist;
                    var overrideBatchDataObject = {
                        overrideBatchData: overrideBatchData,
                        tierId: tierId,
                        wineClubProdList: wineClubProdList
                    };
                    var overrideBatchDataJSON = {
                        overrideBatchDataObject: JSON.stringify(overrideBatchDataObject)
                    };
                    let that = this;
                    $.ajax({
                        url: submitUrl,
                        data: overrideBatchDataJSON,
                        type: 'POST',
                        dataType: 'JSON',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            Accept: 'application/json',
                            'x-requested-with': 'XMLHttpRequest'
                        },
                        success: function (res) {
                            if (res.response) {
                                that.closeModal();
                                window.location.reload();
                            } else if (!res.response) {
                                if (res.validation) {
                                    $('.wineshipment-override-error').find('.errorMesg').text('');
                                    $('.wineshipment-override-error').find('.errorMesg').text(res.validation.errorMesg);
                                    $('.override-batch-sc-error').html('');
                                    $('.override-batch-sc-error').html(res.validation.complianceErrorMesg);
                                }
                                $('.override-batch_submit-button').attr('disabled', true);
                                return;
                            }
                        },
                        error: function (err) {
                            console.log(err);
                        }
                    });
                }
            });

            $(document).on('click', '.override-cancel_button', (event) => {
                this.closeModal();
            });
        }

        getAllProducts(searchInput, selectedProducts) {
            let overrideBatchURL = document.getElementById('overrideBatchURL');
            if (overrideBatchURL) {
                let url = overrideBatchURL.value;
                let searchData = {}
                if(searchInput) {
                    searchData.q = searchInput;
                }
                if(selectedProducts && selectedProducts.length) {
                    searchData.selectedProducts = selectedProducts;
                }
                var searchDataJSON = { searchData: JSON.stringify(searchData)};
                if(searchInput || (selectedProducts && selectedProducts.length)) {
                    $.ajax({
                        url: url,
                        data: searchDataJSON, 
                        type: 'get',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            Accept: 'application/json',
                            'x-requested-with': 'XMLHttpRequest'
                        },
                        success: function (res) {
                            $('.override-batch-dialog-inner').html(res.overrideShipmentProductsHtml);
                            if(!res.overrideShipmentProductsHtml) {
                                $('.override-batch_dialog-header .m-no_results').removeClass('h-hidden');
                            } else {
                                $('.override-batch_dialog-header .m-no_results').addClass('h-hidden');
                            }
                        },
                        error: function (err) {
                            console.log(err);
                        }
                    });
                } else {
                    $.ajax({
                        url: url,
                        type: 'get',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            Accept: 'application/json',
                            'x-requested-with': 'XMLHttpRequest'
                        },
                        success: function (res) {
                            $('.override-batch-dialog-inner').html(res.overrideShipmentProductsHtml);
                            if(!res.overrideShipmentProductsHtml) {
                                $('.override-batch_dialog-header .m-no_results').removeClass('h-hidden');
                            } else {
                                $('.override-batch_dialog-header .m-no_results').addClass('h-hidden');
                            }
                        },
                        error: function (err) {
                            console.log(err);
                        }
                    });
                }
            }
        }
    }

    return OverrideBatchModal;
}
