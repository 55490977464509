import { getJSONByUrl } from 'widgets/toolbox/ajax';

/**
 * @description HarmonyAddressList implementation
 * @param {AddressList} AddressList widget for extending
 * @returns {typeof HarmonyAddressList}
 */
export default function (AddressList) {
    return class HarmonyAddressList extends AddressList {
        /**
         * @description Remove address
         * @listens dom#click
         * @emits "alert.show"
         * @returns {void}
         */
        confirmedRemoveAddress() {
            if (!this.addressToRemove) { return; }

            const addressId = this.addressToRemove.data('id');
            const isDefault = this.addressToRemove.data('default');

            const promise = getJSONByUrl(this.addressToRemove.data('url'), {
                addressId,
                isDefault
            }).then((response) => {
                const addressCard = this.ref(addressId);
                const { defaultAddress, urls } = response;
                const accessibilityAlert = response.message
                    ? response.message
                    : this.prefs().accessibilityAlerts.addressRemoved;

                if (response.error !== true) {
                    this.updateAddressTilesView(addressCard, defaultAddress, urls);
                }

                this.eventBus().emit('alert.show', {
                    accessibilityAlert
                });
            });

            this.eventBus().emit('loader.start', promise);
        }

        /**
         * Remove address tile from storefront view.
         *
         * @param addressCard
         * @param defaultAddress
         * @param urls
         */
        updateAddressTilesView(addressCard, defaultAddress, urls) {
            const addNewAddressElement = this.ref('addNewAddress').get();

            if (!addressCard) { return; }

            addressCard.remove();
            delete this.addressToRemove;

            if (addressCard.data('isDefaultAddress')) {
                if (defaultAddress) {
                    this.updateAddressTile(defaultAddress.address.addressId, {
                        isDefault: true,
                        address: defaultAddress,
                        urls
                    });
                } else {
                    this.ref('msgBlock').show();
                }
            }

            if (addNewAddressElement) {
                addNewAddressElement.focus();
            }
            window.location.reload();
        }

        setDefaultShippingAddress(address) {
            const URL = address.data('url');
            const promise = getJSONByUrl(URL, {
            }).then((response) => {
                if (response.success === true) {
                    location.reload();
                } else {
                    document.getElementById('shipping-address').textContent = response.errorMsg;
                    let element = document.getElementById('shipping-address');
                    element.classList.remove('h-hidden');
                    window.scrollTo(0, 0);
                }
            });

            this.eventBus().emit('loader.start', promise);
        }
    };
}
