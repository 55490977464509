window.$ = require('jquery');
require('./wineclubRegistrationExisting');
require('./editShipment');
require("./ajaxshipment");
require('./waitlistFormAjax');
$(document).ready(function () {
    if ($('.round-decimal').length > 0) {
        $('.round-decimal').each(function () {
            let item = parseFloat($(this).text());
            item = item.toFixed(2);
            $(this).text(item);
        });
    }

    if ($('.member-only-product').length > 0) {
        $('.member-only-product').each(function() {
            $(this).parent().find('.b-product_tile-quantity').hide()
            $(this).parent().find('.b-product_tile-cta:not(.member-only-product)').hide()
        })
    }
});
