require('./app');
import WidgetsMgr from 'widgets/widgetsMgr';
import HarmonyModal from 'harmony/global/HarmonyModal';
import Modal from 'widgets/global/Modal';
import HarmonyAddressList from 'harmony/account/HarmonyAddressList';
import WineClubModal from './WineClubModal';
import RescheduleModal from './RescheduleModal';
import EditShipmentMethodModal from './EditShipmentMethodModal';
import SwapBottlesModal from './SwapBottlesModal';
import OverrideBatchModal from './OverrideBatchModal';
import cancelOrderModal from 'harmony/cancelOrderModal';
import ManageEquinoxModal from './manageEquinoxModal';
import ManageMilkRunModal from './manageMilkRunModal';
import managePreferenceModal from './managePreferenceModal';
import AccessibilityFocusTrapMixin from 'widgets/global/AccessibilityFocusTrapMixin';

WidgetsMgr.addWidgetsList('app_brand_orinswift', () => [
    ['accessibilityFocusTrapMixin', AccessibilityFocusTrapMixin],
    ['modal', Modal, 'accessibilityFocusTrapMixin'],
    ['harmonyModal', HarmonyModal, 'modal'],
    ['addressList', HarmonyAddressList, 'addressList'],
    ['WineClubModal', WineClubModal, 'harmonyModal'],
    ['RescheduleModal', RescheduleModal, 'harmonyModal'],
    ['ManageEquinoxModal', ManageEquinoxModal, 'harmonyModal'],
    ['ManageMilkRunModal', ManageMilkRunModal, 'harmonyModal'],
    ['managePreferenceModal', managePreferenceModal, 'harmonyModal'],
    ['SwapBottlesModal', SwapBottlesModal, 'harmonyModal'],
    ['OverrideBatchModal', OverrideBatchModal, 'harmonyModal'],
    ['cancelOrderModal', cancelOrderModal, 'harmonyModal'],
    ['EditShipmentMethodModal', EditShipmentMethodModal, 'harmonyModal']
]);
