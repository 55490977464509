$(document).ready(function () {
    var sessionProdIDs = window.sessionStorage.getItem('addedProdIDs');
    if (sessionProdIDs) {
        window.sessionStorage.removeItem('addedProdIDs');
    }
    var removedProdIDs = window.sessionStorage.getItem('removedProdIDs');
    if (removedProdIDs) {
        sessionStorage.removeItem('removedProdIDs');
    }
    function alertProductAdded() {
        const globalAlertSelector = '.b-global_alerts-item.m-hide';
        $(globalAlertSelector).removeAttr('hidden');
        window.scrollTo(0, 500);
        $(globalAlertSelector).html('Product Added to the shipment');
        setTimeout(function () {
            $(globalAlertSelector).attr('hidden', 'hidden').html('');
        }, 4000);
    }
    var isMemberShipChanged = false;

    $(document).on('click', '#add-bottles-to-Shipment .b-product_tile-cta.m-short', function () {
        $('#saveShipment-cta').attr('disabled', false);
        var pid = $(this)[0].dataset.pid;
        var titleShowUrl = $('#titleShowUrl').val();
        var analytic = JSON.parse($('#WcSignupData').val());
        var quantity = $(this).prev().find('input').val() || "";
        var subGroup = $(this).closest('.b-product-shortname').data('shortname');
        var removePids = JSON.parse(sessionStorage.getItem('removedProdIDs'));

        var filteredArray = [];
        if (removePids) {
            filteredArray = removePids.filter(e => e.pid !== pid || e.isFromSwap);
        }
        var gtmData = {
            event: 'WCCustomize',
            WCAction: 'Add',
            WCName: analytic.WCName,
            WCId: analytic.WCId,
            brandId: analytic.brandId,
            WCMembershipId: analytic.WCMembershipId,
            sfPersonAccountId: analytic.sfPersonAccountId,
            WCSubgroup: analytic.WCSubgroup,
            WCAddition: pid + '_' + quantity
        };
        dataLayer.push(gtmData);
        if (typeof Storage !== 'undefined') {
            var storedProdID = JSON.parse(sessionStorage.getItem('addedProdIDs'));
            var prodAddshipqyt = $('.shipment-products .added-product-tile').find('div[data-pid=' + pid + ']');
            var mySet;
            var alreadyStrored = storedProdID && storedProdID.indexOf(pid) !== -1;
            if (alreadyStrored || prodAddshipqyt.length > 0) {
                var curProdQyt = $(this).closest('.b-product_tile-actions').find('.b-product_quantity-input');
                var qytCount = curProdQyt.val(); // 4
                if (alreadyStrored) {
                    prodAddshipqyt = $('.shipment-newproducts , .shipment-products').find('div[data-pid=' + pid + ']');
                }
                var prodAddshipqytValue = prodAddshipqyt.find('.b-product_quantity-input');
                var prodshipval = parseInt(prodAddshipqytValue.val());
                var updatedQty;
                if (prodshipval) {
                    updatedQty = parseInt(qytCount) + parseInt(prodAddshipqytValue.val());
                } else {
                    updatedQty = parseInt(qytCount);
                }

                prodAddshipqytValue.val(updatedQty);
                if (storedProdID) {
                    storedProdID.push(pid);
                } else {
                    storedProdID = [];
                    storedProdID.push(pid);
                }
                mySet = new Set(storedProdID);
                storedProdID = [...mySet];
                window.sessionStorage.setItem('addedProdIDs', JSON.stringify(storedProdID));
                alertProductAdded();
            } else if (!storedProdID || storedProdID.length <= 0) {
                var prodIDs = [pid];
                window.sessionStorage.setItem('addedProdIDs', JSON.stringify(prodIDs));
                getAddTile(titleShowUrl, pid, true);
            } else if (storedProdID && storedProdID.indexOf(pid) === -1) {
                getAddTile(titleShowUrl, pid, true);
                storedProdID.push(pid);
                mySet = new Set(storedProdID);
                storedProdID = [...mySet];
                window.sessionStorage.setItem('addedProdIDs', JSON.stringify(storedProdID));
            }
        }
        if (filteredArray && filteredArray.length === 0) {
            sessionStorage.removeItem('removedProdIDs');
        } else {
            window.sessionStorage.setItem('removedProdIDs', JSON.stringify(filteredArray));
        }
    });

    $(document).on('click', '#saveShipment-cta', function () {
        var saveAllCustmizeProd = $('#saveAllCustmizeProd').val();
        var tierId = $('#tierID').val();
        var wineclubProductList = $('#wineClubProdList').val();
        var storedProdID = JSON.parse(sessionStorage.getItem('addedProdIDs'));
        var removedProdIDs = JSON.parse(sessionStorage.getItem('removedProdIDs'));
        var QuantityArr = [];
        if (storedProdID && storedProdID.length) {
            storedProdID.forEach(element => {
                var quantity = $('.shipment-newproducts , .shipment-products').find('.b-product_quantity-input[data-pid="' + element + '"]').val();
                QuantityArr.push(quantity);
            });
        }
        var addshipments = {
            pid: storedProdID,
            quantity: QuantityArr,
            wineClubProdList: wineclubProductList,
            tierId: tierId
        };
        var removeShipmets ={
            pid: removedProdIDs,
            tierId: tierId
        }
        var senddata = {
            productlist: JSON.stringify(addshipments),
            removedPList: JSON.stringify(removeShipmets)
        };
        let swapObject = window.sessionStorage.getItem('swapBottlesDataObject');
        if(swapObject) {
            senddata['swapObject'] = swapObject;
        }
        var selSubgroup = []
        $('.swap-product-tile').each( (el,obj) => {
            var tier = $(obj).find('.prefName').text();
            var quant = $(obj).find('.b-product_quantity-input').val();
            var isSelected = $(obj).find('.radio__input:checked').length;
            if ( isSelected ) {
                selSubgroup.push(tier+"_"+quant);
            }
        })
        // @ts-ignore
        $.ajax({
            url: saveAllCustmizeProd,
            data: senddata,
            type: 'POST',
            dataType: 'JSON',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
                'x-requested-with': 'XMLHttpRequest'
            },
            cache: 'default',
            success: function (res) {
                if(!res.response) {
                    if (res.validation) {
                        $('.wineshipment-error').find('.errorMesg').text('');
                        $('.wineshipment-error').find('.errorMesg').text(res.validation.errorMesg);
                        $('.shipcompliant-error').html('');
                        $('.shipcompliant-error').html(res.validation.complianceErrorMesg);
                    }
                    $('#saveShipment-cta').attr('disabled', true);
                    if (res.validation.shipmentTotal) {
                        $('.wineshipment-priceinfo').find('.price-details').find('span').text(res.validation.shipmentTotal);
                    }
                    return;
                }
                var analytic = JSON.parse($('#WcSignupData').val());
                var gtmData = {
                    event: 'WCCustomize',
                    WCAction: 'Update',
                    WCName: analytic.WCName,
                    WCId: analytic.WCId,
                    brandId: analytic.brandId,
                    WCMembershipId: analytic.WCMembershipId,
                    sfPersonAccountId: analytic.sfPersonAccountId,
                    WCSubgroup: analytic.WCSubgroup
                };
                if(isMemberShipChanged) {
                    gtmData.WCShipmentUpdate = selSubgroup;
                }
                dataLayer.push(gtmData);
                if (res.response) {
                    sessionStorage.removeItem('addedProdIDs');
                    window.location.href = $('#accountWineClubURL').val();
                }
            },
            error: function (error) {
                console.log(error);
            }
        });
    });

    $(document).on('click', '.removeProdFromSession', function () {
        $('#saveShipment-cta').attr('disabled', false);
        var storedProdID = JSON.parse(sessionStorage.getItem('addedProdIDs'));
        let curProdId = null;
        let isFromSwap = false;
        if ($(this).closest('.swap-product-tile').length) {
            isFromSwap = true;
            curProdId = $(this).closest('.swap-product-tile').attr('data-product-id');
        } else {
            curProdId = $(this).closest('.b-product_tile-actions , .added-product-tile').attr('data-product-id');
        }
        var storedRemovedID = JSON.parse(sessionStorage.getItem('removedProdIDs'));
        if (storedRemovedID) {
            storedRemovedID.push({
                pid: curProdId,
                isFromSwap
            });
            window.sessionStorage.setItem('removedProdIDs', JSON.stringify(storedRemovedID));
        } else {
            window.sessionStorage.setItem('removedProdIDs', JSON.stringify([{ pid: curProdId, isFromSwap: isFromSwap }]));
        }
        var filteredAry = [];
        if (storedProdID) {
            filteredAry = storedProdID.filter(e => e !== curProdId || isFromSwap);
        }
        var curRemoveProd = null;
        /**
         * @type {string | number | null | undefined}
         */
        var curRemoveProdIndex = null;
        if (isFromSwap) {
            curRemoveProd = $(this).closest('.swap-product-tile[data-product-id=' + curProdId + ']');
            curRemoveProdIndex = curRemoveProd.find('.shipment-bottle').attr('data-index');
        } else {
            curRemoveProd = $(document).find('.shipment-newproducts , .shipment-products')
                .find('article[data-pid=' + curProdId + ']');
            curRemoveProdIndex = curRemoveProd.attr('data-index');
        }
        curRemoveProd.remove();

        var swapFilteredAry = [];
        var swapBottlesData = window.sessionStorage.getItem('swapBottlesDataObject')
            ? JSON.parse(window.sessionStorage.getItem('swapBottlesDataObject')) : [];
        if (swapBottlesData.length) {
            swapFilteredAry = swapBottlesData.filter(e => parseInt(e.index) !== parseInt(curRemoveProdIndex));
        }
        if (swapFilteredAry && swapFilteredAry.length !== 0) {
            swapBottlesData = swapFilteredAry;
            window.sessionStorage.setItem('swapBottlesDataObject', JSON.stringify(swapFilteredAry));
        } else {
            swapBottlesData = swapFilteredAry;
            sessionStorage.removeItem('swapBottlesDataObject');
        }
        $(document).find('.shipment-newproducts , .shipment-products').find('.shipment-bottle').each(function () {
            var dataIndex = $(this).attr('data-index');
            // eslint-disable-next-line radix
            if (dataIndex && curRemoveProdIndex && parseInt(dataIndex) > parseInt(curRemoveProdIndex)) {
                $(this).attr('data-index', dataIndex - 1);
                if (swapBottlesData.length) {
                    swapBottlesData.map((ele) => {
                        // eslint-disable-next-line radix
                        if (parseInt(ele.index) === parseInt(dataIndex)) {
                            var newIndex = ele.index - 1;
                            ele.index = newIndex.toString();
                        }
                    });
                    window.sessionStorage.setItem('swapBottlesDataObject', JSON.stringify(swapBottlesData));
                }
            }
        });
        if (filteredAry && filteredAry.length === 0) {
            sessionStorage.removeItem('addedProdIDs');
        } else {
            window.sessionStorage.setItem('addedProdIDs', JSON.stringify(filteredAry));
        }
        var subGroup = $(this).closest('.b-product-shortname').data('shortname');

        // location.reload();
        var qant = $(this).prev().find('input').val() || '1';
        var analytic = JSON.parse($('#WcSignupData').val());
        var gtmData = {
            event: 'WCCustomize',
            WCAction: 'Remove',
            WCName: analytic.WCName,
            WCId: analytic.WCId,
            brandId: analytic.brandId,
            WCMembershipId: analytic.WCMembershipId,
            sfPersonAccountId: analytic.sfPersonAccountId,
            WCRemoval: curProdId+"_"+qant,
            WCSubgroup: analytic.WCSubgroup //subGroup+"_"+qant
        };
        dataLayer.push(gtmData);
    });

    /**
     * @param {string | number | string[] | undefined} titleShowUrl
     * @param {string | undefined} Prodpid
     * @param firstAdd
     */
    function getAddTile(titleShowUrl, Prodpid, firstAdd) {
        var curPid = Prodpid;
        var firstAdd;
        // @ts-ignore
        $.ajax({
            url: titleShowUrl,
            data: {
                pid: Prodpid,
                flow: 'wineclubCustomize'
            },
            type: 'get',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
                'x-requested-with': 'XMLHttpRequest'
            },
            success: function (res) {
                var newproductsSection = $('.shipment-newproducts');
                var sessionProdIDs = JSON.parse(sessionStorage.getItem('addedProdIDs'));
                if(sessionProdIDs && sessionProdIDs.length > 0){
                    newproductsSection.append(res);
                }
                $('.shipment-newproducts .b-product_quantity.wcproduct-quantity').attr('data-wineclub', true);
                if (firstAdd) {
                    var prodAddshipqyt = $(document).find('.shipment-newproducts').find('div[data-pid=' + curPid + ']');
                    var qytCount = $(document).find('#add-bottles-to-Shipment .b-product_tile').find('div[data-pid=' + curPid + ']');
                    var prodAddshipqytValue = prodAddshipqyt.find('.b-product_quantity-input');
                    var qytCountPD = $(qytCount).find('.b-product_quantity-input').val();
                    var updatedQty = parseInt(qytCountPD) + parseInt(prodAddshipqytValue.val()) - 1;
                    prodAddshipqytValue.val(updatedQty);
                    alertProductAdded();
                }
                $(newproductsSection).find('.b-product_tile-cta.m-short').remove();
                $("<button class='b-product_tile-cta m-short removeProdFromSession'>Remove</button>").insertAfter('.shipment-newproducts .b-product_tile-quantity');
            },
            error: function (err) {
                console.log(err);
            }
        });
    }

    /*if (window.location.href.indexOf('Account-Customize') !== -1) {
        var storedProdID = JSON.parse(sessionStorage.getItem('prodIDs'));
        var titleShowUrl = $('#titleShowUrl').val();
        var savedActiveId = sessionStorage.getItem('prodIDSaved');
        for (const property in storedProdID) {
            if (!savedActiveId || savedActiveId.indexOf(storedProdID[property]) === -1) {
                getAddTile(titleShowUrl, storedProdID[property]);
            }
        }
        var prodList = [];
        for (var i = 0; i < $('.added-product-tile').length; i++) {
            prodList.push($($('.added-product-tile')[i]).attr('data-pid'));
        }
        window.sessionStorage.setItem('prodIDs', JSON.stringify(prodList));
        window.sessionStorage.setItem('prodIDSaved', JSON.stringify(prodList));
    }*/

    $('.customizecard').click(function () {
        window.sessionStorage.setItem('addedProdIDs', JSON.stringify([]));
        // window.sessionStorage.setItem('prodIDSaved', JSON.stringify([]));
        window.sessionStorage.setItem('removedProdIDs', JSON.stringify([]));
        window.sessionStorage.setItem('swapBottlesDataObject', JSON.stringify([]));
    });

    $(document).on('click', '.shipment-newproducts .wcproduct-quantity, .shipment-products .wcproduct-quantity', function (event) {
        $('#saveShipment-cta').attr('disabled', false);
        var storedProdID = JSON.parse(sessionStorage.getItem('addedProdIDs'));
        if (storedProdID && storedProdID.indexOf(this.dataset.pid) === -1) {
            storedProdID.push(this.dataset.pid);
            var mySet = new Set(storedProdID);
            storedProdID = [...mySet];
            window.sessionStorage.setItem('addedProdIDs', JSON.stringify(storedProdID));
        } else if (!storedProdID) {
            var prodIDs = [this.dataset.pid];
            window.sessionStorage.setItem('addedProdIDs', JSON.stringify(prodIDs));
        }
    });

    $(document).on('click', '.custom-shipment .radio__input', function () {
        $('#saveShipment-cta').attr('disabled', false);
        const $radioChecked = $(this).parent().find('.radio__input:checked');
        isMemberShipChanged = $radioChecked.length ? true : false;
        var discountPrice = $radioChecked[0].dataset.discountedprice;
        var actualPrice = $radioChecked[0].dataset.actualprice;
        $radioChecked.closest('.swap-product-tile').find('.disc-price').html(discountPrice + '/yr');
        $radioChecked.closest('.swap-product-tile').find('.price s').html(actualPrice);
        $radioChecked.closest('.swap-product-tile').find('.b-product_quantity-input').val($radioChecked.val());
        var storedProdID = JSON.parse(sessionStorage.getItem('addedProdIDs'));
        if (storedProdID && storedProdID.indexOf(this.dataset.pid) === -1) {
            storedProdID.unshift(this.dataset.pid);
            var mySet = new Set(storedProdID);
            storedProdID = [...mySet];
            window.sessionStorage.setItem('addedProdIDs', JSON.stringify(storedProdID));
        } else if (!storedProdID) {
            var prodIDs = [this.dataset.pid];
            window.sessionStorage.setItem('addedProdIDs', JSON.stringify(prodIDs));
        }
    });

    $(document).on('change', '#checkInHouse', function() {
        let checked = $(this).is(':checked');
        let url = $(this).attr('data-in-house-shipping-method-url');
        // @ts-ignore
        $.ajax({
            url: url,
            data: { isInHouse: checked },
            type: 'GET',
            dataType: 'JSON',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
                'x-requested-with': 'XMLHttpRequest'
            },
            cache: 'default',
            success: function (res) {
                if (res && res.error) {
                    $('.inhouse-error').text(res.errorMessage);
                    if (checked) {
                        $('#checkInHouse').prop('checked', false);
                    }
                } else {
                    $('.inhouse-error').text('');
                    let successMessage = $('#checkInHouse').attr( checked ? 'data-success-message' : 'data-inhouse-removed-message');
                    $('.b-global_alerts-item').html(successMessage).removeAttr('hidden');
                    setTimeout(function () {
                        $('.b-global_alerts-item').attr('hidden', 'hidden').html('');
                    }, 4000);
                }
            },
            error: function (error) {
                console.log(error);
            }
        });
    });

    var additionalProductsEquinox = document.getElementById('additionalProductsEquinoxURL');
    if (additionalProductsEquinox) {
        var url = additionalProductsEquinox.value;
        $.ajax({
            url: url,
            type: 'get',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
                'x-requested-with': 'XMLHttpRequest'
            },
            success: function (res) {
                $('.add-bottles-to-Shipment-container').html(res);
                return false;
            },
            error: function (err) {
                console.log(err);
            }
        });
    }

    var eventEnquiryCarouselURL = document.getElementById('eventEnquiryCarouselURL');
    if (eventEnquiryCarouselURL) {
        var url = eventEnquiryCarouselURL.value;
        $.ajax({
            url: url,
            type: 'get',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
                'x-requested-with': 'XMLHttpRequest'
            },
            success: function (res) {
                $('.js-event-enquiry-carousel').html(res)
                return false;
            },
            error: function (err) {
                console.log(err);
            }
        });
    }
});
