/* eslint-disable sonarjs/no-duplicate-string */
// import HarmonyModal from 'harmony/global/HarmonyModal';

// @ts-ignore
export default function (HarmonyModal) {
    var plistID = '';
    var url = '';
    var selectedShippingMethod = '';
    var stateCode = '';
    var tierId = '';
    var isBopis = false;
    class EditShipmentMethodModal extends HarmonyModal {
        init() {
            super.init();
            /** @type {never[]} */
            this.ele = [];
        }

        // @ts-ignore
        show(e, id) {
            if (id) {
                url = id.target.attributes['data-action-url'].value;
                plistID = id.target.attributes['data-plistid'].value;
                selectedShippingMethod = id.target.attributes['data-selectedshippingmethod'].value;
                stateCode = id.target.attributes['data-statecode'].value;
                tierId = id.target.attributes['data-tierid'].value;
                isBopis = id.target.attributes['data-isbopis'].value;
            }
            // @ts-ignore
            this.getById('edit-shipment-method', (/** @type {modal} */ editShipmentMethod) => editShipmentMethod
                .showModal({
                    attributes: {
                        'data-tau-unique': 'open_shipment_method_dialog'
                    }
                }));

            var getShippingMethodDataObject = {
                isBopis: isBopis,
                selectedShippingMethod: selectedShippingMethod,
                stateCode: stateCode,
                tier: tierId
            };
            var getShippingMethodDataJSON = {
                getShippingMethodDataObject: JSON.stringify(getShippingMethodDataObject)
            };

            $.ajax({
                url: url,
                data: getShippingMethodDataJSON,
                type: 'get',
                dataType: 'JSON',
                async: true,
                success: async function (data) {
                    if (data.editShippingMethodHTML) {
                        $('.edit-shipment-method-modal .b-dialog-body').html(data.editShippingMethodHTML);
                    }
                },
                error: function () {}
            });
        }

        closeEditShipmentMethodModal() {
            super.closeModal();
            window.location.reload();
        }

        editShipmentMethod() {
            var url = $('.manageShipmentMethodModal').attr('data-action-url');
            var tierName = $(".manageShipmentMethodModal #parentTierName").val();
            var selectedShippingMethodId = $('.select-shipment-method').find(":selected").val();

            var updateShippingMethodDataObject = {
                plistID: plistID,
                selectedShippingMethodId: selectedShippingMethodId,
                tier: tierName
            };
            var updateShippingMethodDataJSON = {
                updateShippingMethodDataObject: JSON.stringify(updateShippingMethodDataObject)
            };
            var that = this;
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: updateShippingMethodDataJSON,
                async: true,
                success: async function (data) {
                    that.closeEditShipmentMethodModal();
                },
                error: function () {}
            });
        }
    }

    return EditShipmentMethodModal;
}
