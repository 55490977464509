window.$ = require('jquery');
import { showErrorLayout } from 'widgets/toolbox/util';
$(document).ready(function () {
    $('#shipmentEditForm').on('submit', function (e) {
        e.preventDefault();
        var url = $('#shipmentEditForm').attr('action');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            data: $('form').serialize(),
            async: true,
            success: async function (data) {
                if (typeof data.newShipmentDate === 'string') {
                    const date = data.newShipmentDate
                        .split('T')[0]
                        .split('-')
                        .reverse();
                    const dd = date[1] + '/' + date[0] + '/' + date[2];
                    const heading = `<h2 class='b-user_greeting-message'>${data.successMessage}</h2>`;
                    const para = `<p class='info-title'>
                ${dd}
                </p>`;
                    await $('#shipment-date-form-heading-container').append(
                        heading
                    );
                    await $('#shipment-date-form-para-container').append(para);
                    await $('#form-before-submitting').hide();
                    await $('#form-after-submitting').show();
                }
            },
            error: function (XMLHttpRequest, textStatus,err) {
                showErrorLayout(err);
            }
        });
    });
});
